import React from 'react';
import './App.css';

import Louis_Napoléon from './/images/Louis_Napoléon_peint_par_François_Gérard.jpg'
import Zeldenthuis_Famillienaam from './/images/Zeldenthuis_Famillienaam.png'
import Sneek_447_1850_1861_0167_Hendrik_Sippes_Zeldenthuis_1787_te_Joure from './/images/Sneek_447_1850-1861_0167 Hendrik Sippes Zeldenthuis 1787 te Joure.jpg'

import { IKwartierStaat, KwartierStaat } from "./kwartierStaat"

// Generatie 1
import Generatie1_Nico_Zeldenthuis from './/images/generation1/Nico_Zeldenthuis.png'
// Generatie 2
import Generatie2_Hendrik_Zeldenthuis from './/images/generation2/Hendrik_Zeldenthuis.png'
import Generatie2_Margaretha_Alida_Honig from './/images/generation2/Margaretha_Alida_Honig.png'
import Generatie2_Hendrik_Zeldenthuis_en_Margaretha_Alida_Honig from './/images/generation2/Hendrik_Zeldenthuis_en_Margaretha_Alida_Honig.png'
// Generatie3
import Generatie3_Sippe_Zeldenthuis from './/images/generation3/Sippe_Zeldenthuis.png'
import Generatie3_Elisabeth_Molenaar from './/images/generation3/Elisabeth_Molenaar.png'
import Generatie3_Christina_Hendrika_Femer from './/images/generation3/Christina_Hendrika_Femer.png'
import Generatie3_Agatha_Woestenburg from './/images/generation3/Agatha_Woestenburg.png'
import Generatie3_Theresia_Jacoba_Maria_de_Groot from './/images/generation3/Theresia_Jacoba_Maria_de_Groot.png'
// Generatie 4
import Generatie4_Hendrik_Zeldenthuis from './/images/generation4/Hendrik_Zeldenthuis.png'
import Generatie4_Afke_van_der_Werf from './/images/generation4/Afke_van_der_Werf.png'

const kwartierStaat_Nico_Zeldenthuis: IKwartierStaat[] = [
  {
    "generation": 1,
    "generationDescription": "",
    "persons": [
      {
        "name": "Nicolaas (Nico) Zeldenthuis",
        "image": Generatie1_Nico_Zeldenthuis,
        "lifeStory": "geboren op maandag 29 januari 1940 te Enkhuizen. Nico is overleden op vrijdag 17 mei 2024 om 11:27 in Naarden, 84 jaar oud. Hij is op maandag 27 mei 2024 gecremeerd in Laren. Hij trouwde op vrijdag 8 maart 1968, 28 jaar oud, in Edam met"
      },
      {
        "name": "Ria Nieuwendijk",
        "lifeStory": "geboren in 1944 te Edam."
      }
    ]
  },
  {
    "generation": 2,
    "generationDescription": "Ouders",
    "persons": [
      {
        "name": "Hendrik (Henk) Zeldenthuis",
        "image": Generatie2_Hendrik_Zeldenthuis,
        "lifeStory": "geboren op donderdag 11 maart 1909 te Leeuwarden. Henk is overleden op woensdag 2 januari 1991 te Zaandam, 81 jaar oud. Hij is begraven op zaterdag 5 januari 1991 in Zaandijk. Hij trouwde, 28 jaar oud, op dinsdag 15 juni 1937 in Enkhuizen met"
      },
      {
        "name": "Margaretha Alida Honig",
        "image": Generatie2_Margaretha_Alida_Honig,
        "lifeStory": "geboren op donderdag 9 november 1905 in Enkhuizen. Margaretha Alida is overleden op nieuwjaarsdag zondag 1 januari 1995 in Zaandam, 89 jaar oud. Zij is begraven op woensdag 4 januari 1995 in Zaandijk."
      },
      {
        "name": " Hendrik en Margaretha",
        "image": Generatie2_Hendrik_Zeldenthuis_en_Margaretha_Alida_Honig,
        "lifeStory": "trouwfoto"
      }
    ]
  },
  {
    "generation": 3,
    "generationDescription": "Grootouders",
    "persons": [
      {
        "name": "Sippe Zeldenthuis",
        "image": Generatie3_Sippe_Zeldenthuis,
        "lifeStory": "geboren op dinsdag 28 mei 1887 in Sneek. Sippe is overleden op woensdag 21 december 1983 in Enkhuizen, 96 jaar oud. Hij is begraven op zaterdag 24 december 1983 in Enkhuizen. Hij trouwde, 20 jaar oud, op dinsdag 26 mei 1908 in Leeuwarden met"
      },
      {
        "name": "Elisabeth (Eke) Molenaar",
        "image": Generatie3_Elisabeth_Molenaar,
        "lifeStory": "geboren op maandag 15 juli 1889 in Leeuwarden. Elisabeth (Eke) is overleden op woensdag 16 april 1930 in Enkhuizen, 40 jaar oud. Zij is begraven in Enkhuizen. Alle kinderen kwamen uit dit huwelijk. Na het overlijden van Eke Molenaar is Sippe Zeldenthuis nog getrouwd met"
      },
      {
        "name": "Christina Hendrika Femer",
        "image": Generatie3_Christina_Hendrika_Femer,
        "lifeStory": "geboren op donderdag 14-01-1892 te Amsterdam, overleden op dinsdag 26-12-1939 te Enkhuizen. De datum van het huwelijk was donderdag 27-11-1930 te Amsterdam."
      },
      {
        "name": "Agatha Woestenburg",
        "image": Generatie3_Agatha_Woestenburg,
        "lifeStory": "geboren op zondag 06-09-1891 te Enkhuizen, overleden op donderdag 31-05-1956 te Enkhuizen. De datum van het huwelijk was donderdag 05-06-1941 te Enkhuizen."
      },
      {
        "name": "Theresia Jacoba Maria de Groot",
        "image": Generatie3_Theresia_Jacoba_Maria_de_Groot,
        "lifeStory": "geboren op zaterdag 16-11-1901 te Amsterdam, overleden op zondag 21-12-1980 te Stede Broec. De datum van het huwelijk was donderdag 20-12-1956 te Enkhuizen"
      }
    ]
  },
  {
    "generation": 4,
    "generationDescription": "Overgrootouders",
    "persons": [
      {
        "name": "Hendrik Zeldenthuis",
        "image": Generatie4_Hendrik_Zeldenthuis,
        "lifeStory": "geboren op donderdag 28 oktober 1852 in Lemmer (Lemsterland). Hendrik is overleden op donderdag 19 maart 1903 in Leeuwarden, 50 jaar oud. Het beroep van Hendrik was Steenhouwer. Hij trouwde, 25 jaar oud, op zondag 26 mei 1878 in Sneek met"
      },
      {
        "name": "Afke van der Werf",
        "image": Generatie4_Afke_van_der_Werf,
        "lifeStory": "geboren op donderdag 19 juni 1851 in Schoterland (Heerenveen). Afke is overleden op Goede Vrijdag 4 april 1947 in Enkhuizen, 95 jaar oud. Zij is begraven op dinsdag 8 april 1947 in Enkhuizen."
      }
    ]
  },
  {
    "generation": 5,
    "generationDescription": "Betovergrootouders",
    "persons": [
      {
        "name": "Sippe Hendriks Zeldenthuis",
        "lifeStory": "geboren op maandag 5 december 1814 in Joure (Haskerland). Sippe Hendriks is overleden op woensdag 21 november 1883 in Sneek, 68 jaar oud. Het beroep van Sippe was Pottenbakker. Hij trouwde, 28 jaar oud, op zondag 14 mei 1843 in de Grietenij Lemsterland met"
      },
      {
        "name": "Martje Foekes Kroontje",
        "lifeStory": "geboren in 09-juni-1821 in Follenga (Lemsterland). Martje Foekes is overleden op 29 september 1906 te Leeuwarden, 85 jaar oud."
      }
    ]
  },
  {
    "generation": 6,
    "generationDescription": "Oudouders",
    "persons": [
      {
        "name": "Hendrik Sippes Zeldenthuis",
        "lifeStory": "geboren op dinsdag 13 februari 1787 te Joure (Westermeer). Hij nam op vijf maart 1812 de naam ZELDENTHUIS als familienaam aan. Hendrik Sippes is overleden op zaterdag 30 mei 1857 in Sneek, 70 jaar oud. Het beroep van Hendrik was Pottenbakker. Hij trouwde, 26 jaar oud, op zondag 14 november 1813 in Joure met"
      },
      {
        "name": "Catharina Jacobus Pesch",
        "lifeStory": "geboren op zaterdag 10 april 1784 in Leeuwarden. Catharina Jacobus is overleden op maandag 1 september 1828 in Joure, 44 jaar oud. Na het overlijden van Catharina Jacoba Pesch is Hendrik Sippes getrouwd met"
      },
      {
        "name": "Hielkjen Rienks Vogelvanger",
        "lifeStory": "geboren op 20 november1789 te Joure. Hielkjen Rienks is overleden op 21 juni 1854 te Haskerland, 65 jaar oud. De datum van het huwelijk was op maandag 25-04-1831 te Grietenij Haskerland."
      }
    ]
  },
  {
    "generation": 7,
    "generationDescription": "Oudgrootouders",
    "persons": [
      {
        "name": "Sippe Hendriks",
        "lifeStory": "geboren op dinsdag 24 juni 1755 te St. Nicolaasga. Sippe Hendriks is overleden in 1794 te Joure (Idskenhuizen). Het beroep van Sippe was Kastelijn. Hij trouwde, oud 25 jaar, op zaterdag 6 maart 1781 in Joure met"
      },
      {
        "name": "Trijntje Tiedzes de Vries (Tietzes ook genoemd Teedes)",
        "lifeStory": "geboren op ? in ? oud ?.  Tiedzes de Vries is overleden ? te ? ? jaar oud."
      }
    ]
  },
  {
    "generation": 8,
    "generationDescription": "Oudovergrootouders",
    "persons": [
      {
        "name": "Hendrik Willems",
        "lifeStory": "geboren op ? overleden in ? te ?. Het beroep van Hendrik was Huisman (boer) te Idskenhuizen. Hij trouwde op zaterdag 16 januari 1748 in St. Nicolaasga (RK). Vermoedelijk stamt hier de katholieke tak van de familie Zeldenthuis af. In Friesland woont ook een hervormde tak. met"
      },
      {
        "name": "Trijntje Dircks",
        "lifeStory": "geboren op 19 februari 1723 te St. Nicolaasga. Trijntje wordt op 12 jarige leeftijd wees en trouwde oud 24 jaar met Hendrik. Getuigen waren Hilik Gerrits en Anna Mulder. Trijntje is overleden in 1755 te Idskenhuizen."
      }
    ]
  }
];

function App() {
  return (
    <main className="min-h-screen">
      <h1 className="text-5xl text-center">
        De stamboom van de familie Zeldenthuis
      </h1>
      <h2 className="m-4 text-3xl text-center">Hoe is de naam Zeldenthuis ontstaan?</h2>
      <div className="flex">
        <div className="basis-1/4 m-1 relative">
          <img src={Louis_Napoléon} alt="Louis Napoléon" />
        </div>
        <div className="basis-3/4 m-2">
          <p>
            Lodewijk Napoleon (Louis Napoléon Bonaparte) doet op 1 juli 1810
            afstand van zijn troon. Napoleon Bonaparte stuurt op 14 juli de
            fransman Lebrun naar Amsterdam om het bewind in handen te nemen
            gedurende de overgangstijd. Vooraanstaande Nederlanders zoals Gogel,
            Six, Mollerus, Dirk van Hoogendorp, Van Maanen en Admiraal Verhuell
            nemen deel aan het bestuur. Het land wordt verdeeld in Departementen
            en Arrondissementen en de Burgerlijke Stand wordt ingevoerd. Het
            invoeren van de Burgerlijke Stand had tot doel om iedereen te
            registreren en daardoor was het tevens mogelijk om de mannen op te
            roepen voor de dienstplicht, want Napoleon had veel mensen nodig om
            oorlog te voeren. Men vond het inschrijven bij de burgerlijke stand
            maar flauwekul en dacht dat als de Fransen vertrokken waren dit alles
            weer over zou gaan, vandaar men ook de vreemdste namen opgaf
            bijvoorbeeld; Naaktgeboren, Zoetekouw, Vroegindewei, en... <b>Zeldenthuis</b>.
          </p>
        </div>
      </div>
      <div>
        <p>
          Ook werden namen opgegeven naar het beroep wat men uitoefende zoals:
          Visser, Bakker, Timmerman, Paardenkoper, Metselaar, Sjouwerman enz. Vóór
          deze tijd had men geen achternamen, men had een voornaam en naam van
          zijn vader. Een van onze voorvaders heette Hendrik en was de zoon van
          Sippe. Zijn naam was: Hendrik, Sippe&apos;s (zoon) en woonde in Joure. In
          onderstaande verklaring staat dat Hendrik Sippes zijn naam toen werd <b>Hendrik Zeldenthuis</b>.
        </p>
      </div>
      <div className="m-4">
        <div className="flex justify-center">
          <img className="auto" src={Zeldenthuis_Famillienaam} alt="Zeldenthuis familienaam" />
        </div>
        <p className="text-center text-xs">
          ...dat hij aanneemt de naam van Zeldenthuis voor familie-naam op den
          vijfden maart een duizend agthonderd en twaalf...
        </p>
      </div>
      <div className="m-4">
        Dit is ook terug te vinden in de archieven van <a href="https://allefriezen.nl/">https://allefriezen.nl/</a>.
        Hieronder de inschrijving van Hendrik Sippes Zeldenthuis (Bovenste regel)
        <div className="flex justify-center">
          <img className="auto" src={Sneek_447_1850_1861_0167_Hendrik_Sippes_Zeldenthuis_1787_te_Joure} alt="Hendrik Sippes Zeldenthuis" />
        </div>
        <p className="text-center text-xs">
          Zeldenthuis Hendrik 1787 Joure - 30 mei 1857
        </p>
      </div>
      <div className="text-3xl text-center mt-20 mb-4">
        Kwartierstaat van Nico Zeldenthuis
      </div>
      <KwartierStaat kwartierStaat={kwartierStaat_Nico_Zeldenthuis} />
    </main>
  )
}

export default App;
