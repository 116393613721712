import React from "react";

export interface IPerson {
  name: string;
  image?: string;
  lifeStory: string;
}

export interface IKwartierStaat {
  generation: number;
  generationDescription: string;
  persons: IPerson[];
}

const Person = (props: { person: IPerson }) =>
  <div className="flex flex-row m-2">
    <div className="basis-1/6 m-1">
      {props.person.image && <img src={props.person.image} alt={props.person.name} />}
    </div>
    <div className="basis-5/6 m-2">
      <div className="inline font-bold">{props.person.name}</div>, {props.person.lifeStory}
    </div>
  </div>

export const KwartierStaat = (props: { kwartierStaat: IKwartierStaat[] }) =>
  <>
    {props.kwartierStaat.map((g) =>
      <div key={g.generation}>
        <p className="text-center text-xl">{`Generatie ${g.generation}`} {g.generationDescription && `(${g.generationDescription})`}</p>
        <div className="container border rounded-md m-10">
          {g.persons.map((p) => (
            <div key={p.name} className="text-base">
              < Person person={p} />
            </div>
          ))}
        </div>
      </div>
    )}
  </>